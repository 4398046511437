import React from 'react';
import './Carousel.css'
import Carousel from 'react-bootstrap/Carousel';

class CustomCarousel extends React.Component {
    render() {
        return <Carousel id="carouselfont">
          <Carousel.Item>
            <img
              className="d-block w-100"
            //   src="IMG_6841.jpg"
              alt="First slide"
            />
            <Carousel.Caption>
              <h3>First slide label</h3>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
          <img
              className="d-block w-100"
            //   src="IMG_8717.JPG"
              alt="First slide"
            />
            <Carousel.Caption>
              <h3>Second slide label</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
          <img
              className="d-block w-100"
            //   src="IMG_8757.JPG"
              alt="First slide"
            />
            <Carousel.Caption>
              <h3>haiiii</h3>
              <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
    }
}

export default CustomCarousel;