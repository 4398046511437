import React from 'react';
import './App.css';
import CustomCarousel from './Carousel.js';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faInstagram, faEtsy } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import Image from "react-bootstrap/Image";

// import Home from './Home.js';


function App() {
  return (
    <div> 
      <Navbar collapseOnSelect expand="md" bg="light" variant="light" className="text-center mt-4 mb-4 navtest" sticky="top">
        <Navbar.Brand><h3>THE BRAGANZA POTTERY</h3></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto text-center mt-4 mb-4 buttonsfont">
            <Nav.Link href="/"><h7>HOME</h7></Nav.Link>
            <Nav.Link href="/"><h7>ABOUT ME</h7></Nav.Link>
            <Nav.Link href="/"><h7>MY WORK</h7></Nav.Link>
            <Nav.Link href="/"><h7>SHOP</h7></Nav.Link>
            <Nav.Link href="/"><h7>CONTACT</h7></Nav.Link>
            <Nav.Link href="/"><h7>COMMISSIONS</h7></Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Container fluid className="text-center mt-4 mb-4 textfonts">
        <Row>
          <Col md={12} className='bg-light'>
            <CustomCarousel/>
          </Col>
        </Row>
        <Row className="justify-content-md-center"> 
          <h1>About Me</h1>
        </Row>
        <Row> 
          <Col xs={6} md={4}>
            <Image src="norwaysmile.jpg" roundedCircle fluid />
          </Col>
          <Col>
          I graduated from the University of Bristol with a Bachelors degree in Mechanical Engineering in 2019. 
          It was the incredible city of Bristol that helped me find a love for pottery, being a kiln officer for each year of university. 
          Having done Fine Art at A Level and with a degree in engineering under my belt, I have a great passion for sculptures, 
          the form of the body and diversity in art. I am currently a burgeoning thrower and am yet to hone my skills in- 
          the majority of it is self taught with the exception of a few throwing lessons from a lovely lecturer in Bristol. 
          My thrown pots are a bit wobbly and stout but I'm getting there! In the lockdown of 2020 I decided to take the leap and 
          invest in my new baby, a beautiful Shimpo Aspire RK5T Tabletop Wheel.
          My studio is currently my back garden until I can build up the funds to build a shed 
          and deck it out with all things pottery. This tends to mean my work is quite seasonal 
          (just to stop my fingers from dropping off during the U.K winters) but I love to build new things whenever 
          the British weather permits it!
          </Col>
        </Row>
        {/* <Row className="justify-content-md-center"> 
          <CustomHexagonGrid
          />
        </Row> */}
        <Row className="justify-content-md-center"> 
          <h1>My Work</h1>
        </Row>
        <Row>
          <Col>
            <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src="./norwaysmile.jpg" />
             <Card.Body>
             <Card.Title>Sculptures</Card.Title>
              <Card.Text>
                A handbuilt series of diverse and beautiful sculptures that can perched anywhere.
              </Card.Text>
              <Button variant="outline-dark">Go somewhere</Button>
             </Card.Body>
             </Card>
          </Col>
          <Col>
            <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src="./norwaysmile.jpg" />
             <Card.Body>
             <Card.Title>Vases and Pots</Card.Title>
              <Card.Text>
                Hand-built and hand-carved stoneware vases and pots of different dimensions and designs.
              </Card.Text>
              <Button variant="outline-dark">Go somewhere</Button>
             </Card.Body>
             </Card>
          </Col>
          <Col>
            <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src="./norwaysmile.jpg" />
             <Card.Body>
             <Card.Title>Crockery</Card.Title>
              <Card.Text>
                A variety of cups, bowls and plates usually thrown on the wheel and hand-painted with slip.
              </Card.Text>
              <Button variant="outline-dark">Go somewhere</Button>
             </Card.Body>
             </Card>
          </Col>
          <Col>
            <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src="./norwaysmile.jpg" />
             <Card.Body>
             <Card.Title>Miscellaneous</Card.Title>
              <Card.Text>
                A random assortment of hand built sculptures and accessories that I have been inspired to create. 
              </Card.Text>
              <Button variant="outline-dark">Go somewhere</Button>
             </Card.Body>
             </Card>
          </Col>
        </Row>
       
        <Row className="justify-content-md-center"> 
          <h1>Commissions</h1>
        </Row>
        <Row>
         I would happily produce commissioned work but due to a busy work schedule and the majority of my work being 
         weather-dependant (which isn't particularly a great situation in the British weather), I unfortunately can't make any promises.
        </Row>
        <Row className="justify-content-md-center"> 
          <h1>Contact</h1>
          </Row>
          <Row className="justify-content-md-center">
           Please feel free to contact me through the links below, I'm always happy to discuss my work and potential commissions.  
          </Row>
          <Row className="justify-content-md-center">
          <p> 
          <a className="icon-a" href="https://www.linkedin.com/in/samuel-s-3aa133a9/" target="blank"><FontAwesomeIcon className="link-icon" icon={faLinkedin} size="2x" /></a>
          <a className="icon-a" href="https://mail.google.com/mail/?view=cm&fs=1&to=ssutherlanddee@gmail.com" target="blank"><FontAwesomeIcon className="link-icon" icon={faEnvelope} size="2x" /></a>
          <a className="icon-a" href="https://mail.google.com/mail/?view=cm&fs=1&to=ssutherlanddee@gmail.com" target="blank"><FontAwesomeIcon className="link-icon" icon={faEtsy} size="2x" /></a>
          <a className="icon-a" href="https://mail.google.com/mail/?view=cm&fs=1&to=ssutherlanddee@gmail.com" target="blank"><FontAwesomeIcon className="link-icon" icon={faInstagram} size="2x" /></a>
          </p>
          </Row>

        
      </Container>
    </div>
  );
}


export default App;
